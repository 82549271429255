import React, { useState } from "react";

const GolfTable = (props) => {
  let bonusHoles = 0;
  const modulo = props.myHcpi % 18;
  const bonus = modulo + props.playingHcpi - props.myHcpi;
  const average = Math.round(props.myHcpi / 18);

  const calcVorgabe = (hcp) => {
    console.log("Modulo + Differenz: ", bonus);

    bonusHoles++;
    return average + 1;
  };

  const handleFrontScoreChange = (e, index) => {
    const newFrontScores = [...frontScores]; // Create a copy of the scores array
    newFrontScores[index] = parseInt(e.target.value); // Update the score at the corresponding index
    setFrontScores(newFrontScores); // Update the scores state with the new array
  };

  const handleBackScoreChange = (e, index) => {
    const newBackScores = [...backScores]; // Create a copy of the scores array
    newBackScores[index] = parseInt(e.target.value); // Update the score at the corresponding index
    setBackScores(newBackScores); // Update the scores state with the new array
  };

  const frontNine = props.holeData.slice(0, 9); // Slice the first 9 holes
  const backNine = props.holeData.slice(9); // Slice the remaining holes starting from index 9

  const [frontScores, setFrontScores] = useState(
    Array(frontNine.length).fill(0)
  ); // Initialize scores state array with zeros
  const [backScores, setBackScores] = useState(Array(backNine.length).fill(0)); // Initialize scores state array with zeros

  const frontParSum = frontNine.reduce((acc, hole) => acc + hole.par, 0);
  const frontLengthSum = frontNine.reduce((acc, hole) => acc + hole.länge, 0);
  let frontNineVorgabe = 0;
  const backParSum = backNine.reduce((acc, hole) => acc + hole.par, 0);
  const backLengthSum = backNine.reduce((acc, hole) => acc + hole.länge, 0);
  let backNineVorgabe = 0;
  const frontScoreSum = frontScores
    .slice(0, 9)
    .reduce((acc, score) => acc + score, 0);
  const backScoreSum = backScores
    .slice(0, 9)
    .reduce((acc, score) => acc + score, 0);

  return (
    <table style={{ margin: "0 auto" }}>
      <thead>
        <tr>
          <th>Hole</th>
          <th>Par</th>
          <th>HCP</th>
          <th>Länge</th>
          <th>Vorg.</th>
          <th>Score</th>
        </tr>
      </thead>
      <tbody>
        {frontNine.map((hole) => {
          const vorgabe =
            hole.hcp <= bonus && bonusHoles < bonus
              ? calcVorgabe(hole.hcp)
              : average;

          frontNineVorgabe = frontNineVorgabe + vorgabe;

          return (
            <tr key={hole.hole}>
              <td>{hole.hole}</td>
              <td>{hole.par}</td>
              <td>{hole.hcp}</td>
              <td>{hole.länge}</td>
              <td>{vorgabe}</td>
              <td>
                <input
                  type="number"
                  value={frontScores[hole.hole - 1]} // Bind the input value to the corresponding score
                  onChange={(e) => handleFrontScoreChange(e, hole.hole - 1)} // Pass the index to the handleScoreChange function
                />
              </td>
            </tr>
          );
        })}
        <tr className="tieScore">
          <td>Front</td>
          <td>{frontParSum}</td>
          <td></td>
          <td>{frontLengthSum}</td>
          <td>{frontNineVorgabe + frontParSum}</td>
          <td>{frontScoreSum}</td>
        </tr>
        <tr><td colSpan="6" className="tieScore"></td></tr>
        {backNine.map((hole) => {
          const vorgabe =
            hole.hcp <= bonus && bonusHoles < bonus
              ? calcVorgabe(hole.hcp)
              : average;

          backNineVorgabe = backNineVorgabe + vorgabe;

          return (
            <tr key={hole.hole}>
              <td>{hole.hole}</td>
              <td>{hole.par}</td>
              <td>{hole.hcp}</td>
              <td>{hole.länge}</td>
              <td>{vorgabe}</td>
              <td>
                <input
                  type="number"
                  value={backScores[hole.hole - 10]} // Bind the input value to the corresponding score
                  onChange={(e) => handleBackScoreChange(e, hole.hole - 10)} // Pass the index to the handleScoreChange function
                />
              </td>
            </tr>
          );
        })}
        <tr className="tieScore">
          <td>Back</td>
          <td>{backParSum}</td>
          <td></td>
          <td>{backLengthSum}</td>
          <td>{backNineVorgabe + backParSum}</td>
          <td>{backScoreSum}</td>
        </tr>
        <tr><td colSpan="6" className="tieScore"></td></tr>
        <tr>
          <td>TOTAL</td>
          <td>{frontParSum + backParSum}</td>
          <td></td>
          <td>{frontLengthSum + backLengthSum}</td>
          <td>
            {frontNineVorgabe + frontParSum + backNineVorgabe + backParSum}
          </td>
          <td>{frontScoreSum+backScoreSum}</td>
        </tr>
        <tr><td colSpan="6" className="endScore"></td></tr>
      </tbody>
    </table>
  );
};

export default GolfTable;
