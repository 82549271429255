export const courseData = {
  name: "Gut Frielinghausen",
  par: 71,
  length: 5197,
  slope: 128,
  cr: 69.6,
  holes: [
    {
      hole: 1,
      par: 4,
      hcp: 3,
      länge: 317,
    },
    {
      hole: 2,
      par: 4,
      hcp: 1,
      länge: 359,
    },
    {
      hole: 3,
      par: 3,
      hcp: 7,
      länge: 205,
    },
    {
      hole: 4,
      par: 4,
      hcp: 5,
      länge: 275,
    },
    {
      hole: 5,
      par: 5,
      hcp: 9,
      länge: 443,
    },
    {
      hole: 6,
      par: 4,
      hcp: 13,
      länge: 271,
    },
    {
      hole: 7,
      par: 3,
      hcp: 15,
      länge: 163,
    },
    {
      hole: 8,
      par: 5,
      hcp: 11,
      länge: 441,
    },
    {
      hole: 9,
      par: 3,
      hcp: 17,
      länge: 101,
    },
    {
      hole: 10,
      par: 4,
      hcp: 14,
      länge: 248,
    },
    {
      hole: 11,
      par: 3,
      hcp: 12,
      länge: 120,
    },
    {
      hole: 12,
      par: 5,
      hcp: 4,
      länge: 507,
    },
    {
      hole: 13,
      par: 3,
      hcp: 16,
      länge: 158,
    },
    {
      hole: 14,
      par: 4,
      hcp: 8,
      länge: 257,
    },
    {
      hole: 15,
      par: 5,
      hcp: 6,
      länge: 447,
    },
    {
      hole: 16,
      par: 3,
      hcp: 18,
      länge: 96,
    },
    {
      hole: 17,
      par: 5,
      hcp: 10,
      länge: 432,
    },
    {
      hole: 18,
      par: 4,
      hcp: 2,
      länge: 357,
    },
  ],
};
