import { useState } from "react";
import "./App.css";
import GolfTable from "./components/GolfTable";
import { courseData } from "./data/holeData";

function App() {
  const [myHcpi, setMayHcpi] = useState(0);
  const courseHandicap =
    myHcpi * (courseData.slope / 113) + (courseData.cr - courseData.par);
  const playingHcpi = Math.round(courseHandicap);

  const handleHcpiChange = (e) => {
    const newValue = parseInt(e.target.value); // Update the score at the corresponding index
    setMayHcpi(newValue); // Update the scores state with the new array
  };

  return (
    <div className="App">
      <main>
        <h1>{courseData.name}</h1>
        <section className="data-grid">
          <span>Par: {courseData.par}</span>
          <span>Länge: {courseData.length}m</span>
          <span>Slope: {courseData.slope}</span>
          <span>CR: {courseData.cr}</span>
        </section>
        <section>
          <div className="handicapInput">
          <p>
            Dein Handicap-Index:
          </p>
            <input
              type="number"
              value={myHcpi} // Bind the input value to the corresponding score
              onChange={(e) => handleHcpiChange(e)} // Pass the index to the handleScoreChange function
            />
          </div>
          <p>Playing Handicap: {playingHcpi}</p>
        </section>
        <section>
          <GolfTable
            holeData={courseData.holes}
            myHcpi={myHcpi}
            playingHcpi={playingHcpi}
          />
        </section>
      </main>
    </div>
  );
}

export default App;
